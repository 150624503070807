import { defaultLoggerConfiguration } from './api/models/LoggerConfiguration';
import { LoginTimeoutService } from './services/login-timeout.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPermissionsModule, NgxPermissionsService, NgxPermissionsGuard } from 'ngx-permissions';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PluginTabLabelComponent } from './components/plugin-tab-label/plugin-tab-label.component';
import { ApiService } from './services/api.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ErrorComponent } from './pages/error/error.component';
import { ConfigurationService } from './services/configuration.service';
import { LoggerService } from './services/logger.service';
import { PanelComponent } from './components/panel/panel.component';
import { DaVinciAppComponent } from './components/panelItems/da-vinci-app/da-vinci-app.component';
import { ConstantSizeComponent } from './components/panelItems/constant-size/constant-size.component';
import { PanelItemComponent } from './components/panelItems/panel-item/panel-item.component';
import { TabbedItemsComponent } from './components/panelItems/tabbed-items/tabbed-items.component';
import { SplitterComponent } from './components/splitter/splitter.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { LoginAuthProfileComponent } from './pages/login-auth-profile/login-auth-profile.component';
import { SamlAutoLoginComponent } from './saml-auto-login/saml-auto-login.component';
import { KeypadComponent } from './components/keypad/keypad.component';
import { LogoutWarnComponent } from './components/logout-warn/logout-warn.component';
import { ApiErrorsInterceptor } from './interceptors/api-errors.interceptor';

const material = [
  MatCardModule,
  MatButtonModule,
  MatDividerModule,
  MatGridListModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTreeModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatBadgeModule,
  MatSnackBarModule,
  MatDialogModule,
  MatExpansionModule
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    PluginTabLabelComponent,
    ErrorComponent,
    PanelComponent,
    DaVinciAppComponent,
    ConstantSizeComponent,
    PanelItemComponent,
    TabbedItemsComponent,
    SplitterComponent,
    NotificationPanelComponent,
    LoginAuthProfileComponent,
    SamlAutoLoginComponent,
    KeypadComponent,
    LogoutWarnComponent
  ],
  imports: [
    BrowserModule,
    MatTabsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    FlexLayoutModule,
    NgxPermissionsModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'prefix',
        component: HomeComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Agent'],
            except: ['Expired'],
            redirectTo: {
              Expired: '/error',
              default: '/login'
            }
          }
        }
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['Agent', 'Expired'],
            redirectTo: {
              Expired: '/error',
              default: '/'
            }
          }
        }
      },
      {
        path: 'saml2/:accountid/:name',
        component: SamlAutoLoginComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {}
        }
      },
      {
        path: 'error/profile',
        component: ErrorComponent
      },
      {
        path: 'error',
        component: ErrorComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {}
        }
      },
      {
        path: '**',
        redirectTo: '/'
      }
    ]),
    ...material
  ],
  entryComponents: [LogoutWarnComponent],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          configService: ConfigurationService,
          loggerService: LoggerService,
          api: ApiService,
          ps: NgxPermissionsService,
          loginTimeoutService: LoginTimeoutService
        ) =>
        async () => {
          await configService.loadConfigurationData();
          loggerService.initialize(defaultLoggerConfiguration);
          api.initialize();
          try {
            const isActive = await api.isActive();
            let iHasLicense = -1;
            await api.hasLicense().then(
              (hasLicense: Boolean) => {
                iHasLicense = hasLicense ? 1 : 0;
              },
              () => {
                iHasLicense = -1;
              }
            );

            if (iHasLicense < 0) {
              // Either is active is false, or auth tokens are not present, in this case, this existing logic will take place
              await api.logout('User does not have a license').catch(() => {});
              ps.loadPermissions([]);
              return;
            } else {
              // If active is true, and has license is true, continue with existing flow
              if (api.backgroundTokenRenewTimer === null) {
                api.backgroundTokenRenewTimer = setInterval(() => {
                  if (!api.cancelRenew) {
                    if (api.isTokenPastHalfway()) {
                      api.backgroundTokenRenew();
                    }
                  } else {
                    api.stopTimersAndLogout();
                  }
                }, 60000);
              }

              if (isActive && iHasLicense !== 1) {
                ps.loadPermissions(['Expired']);
                return;
              }

              const result = isActive && iHasLicense === 1;
              if (result) {
                ps.loadPermissions(['Agent']);
              } else {
                ps.loadPermissions([]);
              }
            }
          } catch (e) {
            ps.loadPermissions([]);
          }
        },
      deps: [ConfigurationService, LoggerService, ApiService, NgxPermissionsService, LoginTimeoutService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorsInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
