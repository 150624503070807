<div *ngIf="isSAMLPolling">
  <div class="loginInProgress">
    <div class="logo-light">
      <img src='{{ "assets/images/" + logoName }}' alt="DaVinci Logo">
    </div>
    <div style="text-align: center;">
      <div class="usernameDiv">
        <label class="usernameLabel" [title]="userName">{{ userName }}</label>
      </div>
      <div class="backToLoginScreen" (click)="cancelSAMLLogin()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <label tabindex="0" role="link">Use another auth profile, or try again</label>
      </div>
    </div>
    <div class="loginUsingAuthProfileText" [matTooltip]="currentAuthInfoName"
      matTooltipShowDelay="30" matTooltipHideDelay="0" matTooltipPosition="after">

      <mat-label class="overflow">Please complete logging in to continue</mat-label>
    </div>
    <div class="spinner-container">
      <img class="spinner" src="./../../assets/images/view_progress.gif" />
    </div>
  </div>
</div>
<div *ngIf="!isSAMLPolling" class="top">
  <mat-card>
    <div class="logo-light">
      <img src='{{ "assets/images/" + logoName }}' alt="">
    </div>
    <div style="text-align: center;">
      <div  class="usernameDiv">
        <label class="usernameLabel" [title]="userName">{{userName}}</label>
      </div>
      <div class="backToLoginScreen"  (keyup)="backToLoginScreenKey($event)"  (click) = "backToLoginScreen()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <label tabindex="0" role="link">Use another Username</label>
      </div>
    </div>
    <div class="loginUsingText">
      <mat-label>Login Using</mat-label>
    </div>
    <form #createForm="ngForm" (ngSubmit)="onSubmit()" *ngIf="daVinciAuthInfo && daivnciAuthSelected_flag">

      <div *ngIf="error">
        <h5 fxLayout="row" fxLayoutAlign="center center">
          <span class="mat-body-1" style="color:rgb(255, 8, 0);">{{errorMessage}}</span>
        </h5>
      </div>

      <mat-form-field color="accent">
        <input type="password" matInput placeholder="Password" [attr.disabled]="inProgress ? 'disabled' : null"
          name="Password" [formControl]="Password" required/>
        <mat-error *ngIf="Password.invalid">{{"Invalid password!"}}</mat-error>
      </mat-form-field>

      <mat-progress-bar color="accent" mode="indeterminate" *ngIf="inProgress"></mat-progress-bar>

      <button mat-button class="mat-raised-button mat-accent" [disabled]="inProgress" type="submit">Login</button>

    </form>
    <button mat-button class="mat-raised-button" *ngIf="daivnciAuthSelected_flag === false" (click)="daivnciAuthSelected_flag = true">
      <img  class="loginIcon" [src]="configService.config.iconPack + 'DaVinci_icon.png'" />
      <label> DaVinci</label>
    </button>
    <div *ngFor="let auth of authInfos" (click)="SAMLLogin(auth)">
      <button mat-button class="mat-raised-button">
        <img *ngIf="auth.image && (auth.image !== '')" class="loginIcon" [src]="auth.image" />
        <label>  {{auth.name}}</label></button>

    </div>
  </mat-card>


</div>
