import { ContextualContactsService } from './contextual-contacts.service';
import { OFHelper } from './OFHelper';
import { ApiService } from './api.service';
import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { LogoutWarnComponent } from '../components/logout-warn/logout-warn.component';
import { MatDialog } from '@angular/material/dialog';
import { IGlobalConfiguration } from '../model/GlobalConfiguration';

@Injectable({
  providedIn: 'root'
})
export class LoginTimeoutService {
  currentTime: Date;
  inactiveTimeLimit: Date; // Time of auto logout
  // Set Timeout for once the grace period is over (Grace period: Time a user may remain inactive without warning displayed)
  inactivityTimer: any;
  otherBrowsers: any;
  otherTabCancel: any;
  warningStateToFalse: any;
  // An interval to check if the inactive timer in LS has passed. This is for when the tab goes to sleep
  pastInactiveTimeInterval: any;
  pastInactiveTime = false;
  popUpInterval;
  globalConfig: IGlobalConfiguration;

  constructor(
    private dialog: MatDialog,
    private configService: ConfigurationService,
    private apiService: ApiService // private ofHelper: OFHelper
  ) {}

  // Create event listeners for mouse click, keydown, touchstart, and DOM change
  initialize() {
    window.addEventListener('click', this.throttle(this.resetInactivityTimer.bind(this), 500).bind(this));
    window.addEventListener('keydown', this.throttle(this.resetInactivityTimer.bind(this), 500).bind(this));
    window.addEventListener('touchstart', this.throttle(this.resetInactivityTimer.bind(this), 500).bind(this));
  }

  // Upon logging in, a timer is set that can track user activity. If no user activity, the function in set timeout will trigger
  startInactivityTimer() {
    this.currentTime = new Date();
    this.inactiveTimeLimit = this.currentTime;
    this.inactiveTimeLimit.setSeconds(this.inactiveTimeLimit.getSeconds() + parseInt(this.inactivityTimer, 10) / 1000);
    localStorage.setItem('LoginTimeout', this.inactiveTimeLimit.toString());
    localStorage.setItem('WarningState', 'false');
    this.popUpInterval = setInterval(this.popUpDialog.bind(this), 1000);
    this.otherBrowsers = setInterval(this.checkLocalStorage.bind(this), 0); // Check if there are more than 1 CS browsers open
    this.pastInactiveTimeInterval = setInterval(this.checkLocalStorageTime.bind(this), 1000);
  }

  // Clear current timeout and reset inactivity timer since user interacted with the page
  async resetInactivityTimer() {
    this.currentTime = new Date();
    this.inactiveTimeLimit = this.currentTime;
    this.inactiveTimeLimit.setSeconds(this.inactiveTimeLimit.getSeconds() + parseInt(this.inactivityTimer, 10) / 1000);
    localStorage.setItem('LoginTimeout', this.inactiveTimeLimit.toString());
  }

  // After an event is found, there is a 0.5 second delay between events
  throttle(callback, wait) {
    let timeout;
    return function (e) {
      if (timeout) {
        return;
      }
      timeout = setTimeout(() => (callback(e), (timeout = undefined)), wait);
    };
  }

  // Open Warning Dialog, if it is closed, reset inactivity timer
  async inactivityWarning() {
    const dialogRef = this.dialog.open(LogoutWarnComponent, {
      disableClose: true
    });
    if (await dialogRef.afterClosed().toPromise()) {
      this.currentTime = new Date();
      this.inactiveTimeLimit = this.currentTime;
      this.inactiveTimeLimit.setSeconds(this.inactiveTimeLimit.getSeconds() + parseInt(this.inactivityTimer, 10) / 1000);
      this.pastInactiveTime = false;
      localStorage.setItem('LoginTimeout', this.inactiveTimeLimit.toString());
      this.popUpInterval = setInterval(this.popUpDialog.bind(this), 1000);
    }
  }

  // Check local storage for activity on other cs browsers
  checkLocalStorage() {
    const localStorageTime = new Date(localStorage.getItem('LoginTimeout'));
    if (localStorageTime > this.inactiveTimeLimit) {
      this.inactiveTimeLimit = localStorageTime;
    }
  }

  // Checks to see if the current time is past the logout time
  // If it is past the logout time and the user has not been logged out, logout all browsers
  checkLocalStorageTime() {
    const localStorageTime = new Date(localStorage.getItem('LoginTimeout'));
    const currentTime = new Date();
    const isTimeGreaterThanLS = currentTime.getTime() - localStorageTime.getTime();
    if (isTimeGreaterThanLS >= 2000) {
      this.apiService.stopTimersAndLogout();
      localStorage.setItem('SelectedLogout', 'true');
    }
  }

  // Pops up the warning dialog when there is 60 seconds remaining until the inactivity maximum limit occurs
  popUpDialog() {
    const localStorageTime = new Date(localStorage.getItem('LoginTimeout'));
    const currentTime = new Date();
    const isTimeGreaterThanLS = localStorageTime.getTime() - currentTime.getTime();
    if (isTimeGreaterThanLS <= 60000) {
      this.inactivityWarning();
      clearInterval(this.popUpInterval);
    }
  }
}
