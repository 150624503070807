/**
 * @packageDocumentation
 * @module DaVinci_API
 */

/**
 * This is the configuration of the app according to the admin tool.
 * Returned by [[initializeComplete]].
 */
export interface IAppConfiguration {
  variables: IAppConfigurationVariables;
  [child: string]: IAppConfiguration | IAppConfigurationVariables;
}
/**
 * The variables of an [[IAppConfiguration]]
 */
export interface IAppConfigurationVariables {
  [key: string]: boolean | number | string | string[];
}
