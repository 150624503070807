import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { OFHelper } from '../../services/OFHelper';
import { NOTIFICATION_TYPE, INotificationMessage } from '../../api/AmcApi';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.css']
})
export class NotificationPanelComponent implements OnInit {
  @Input() notificationMessages: INotificationMessage[];
  scrolledToTop: boolean;
  isExpanded = false;
  isCopied = false;
  canCopyToClipboard = false;

  constructor(private _loggerService: LoggerService, private changeDetector: ChangeDetectorRef) {
    if (window.scrollY === 0 || !window.hasOwnProperty('scrollY')) {
      this.scrolledToTop = true;
    } else {
      this.scrolledToTop = false;
    }
  }

  ngOnInit() {
    this._loggerService.logger.logDebug(`ngOnInit of NotificationPanelComponent: "${JSON.stringify(this.notificationMessages)}" `, 3014);

    this.checkCanCopyToClipboard();
  }

  async checkCanCopyToClipboard() {
    const result = await navigator.permissions.query({
      name: 'clipboard-write' as PermissionName
    });

    if (result.state === 'granted') {
      this.canCopyToClipboard = true;
      this.changeDetector.markForCheck();
    }
  }

  clearNotifications() {
    OFHelper.notificationMessages$.next([]);
  }

  clearNotificationsKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.clearNotifications();
    }
  }

  scrollFunction(event) {
    if (window.hasOwnProperty('scrollY') && window.scrollY > 0) {
      this.scrolledToTop = false;
    } else {
      this.scrolledToTop = true;
    }
  }

  isAlert(notificationType: NOTIFICATION_TYPE) {
    return notificationType === NOTIFICATION_TYPE.Alert;
  }

  isError(notificationType: NOTIFICATION_TYPE) {
    return notificationType === NOTIFICATION_TYPE.Error;
  }

  isWarning(notificationType: NOTIFICATION_TYPE) {
    return notificationType === NOTIFICATION_TYPE.Warning;
  }

  isInformation(notificationType: NOTIFICATION_TYPE) {
    return notificationType === NOTIFICATION_TYPE.Information;
  }

  copyMessageToClipboard() {
    navigator.clipboard.writeText(this.notificationMessages[0].message).then(() => {
      this.isCopied = true;
      this.changeDetector.markForCheck();

      setTimeout(() => {
        this.isCopied = false;
        this.changeDetector.markForCheck();
      }, 10000);
    });
  }
}
