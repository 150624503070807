import { BehaviorSubject } from 'rxjs';
import { ISupportedChannel, CHANNEL_TYPES } from '../api/AmcApi';
import { PLUGIN_POSITION } from './PluginPosition';

export class DaVinciApp {
  private _iframe: HTMLIFrameElement = null;
  public get window() {
    return this.iframe.contentWindow;
  }

  public get iframe() {
    if (this._iframe === null) {
      this._iframe = <HTMLIFrameElement>document.querySelector(`iframe[pluginname="${this.name}"]`);
    }
    return this._iframe;
  }

  public config: any;
  public initializationCompleted: boolean;

  public name: string;
  public src: string;
  public visible = true;
  public position: PLUGIN_POSITION;
  public layer: number;
  public icon: string;

  private _inFocus = false;
  get inFocus() {
    return this._inFocus;
  }

  set inFocus(value) {
    this._inFocus = value;
    if (value === true) {
      this.alerts = 0;
    }
  }

  alertsObservable = new BehaviorSubject<number>(0);
  private _alerts = 0;
  set alerts(value) {
    if (this.inFocus) {
      this._alerts = 0;
    } else {
      this._alerts = value;
    }
    this.alertsObservable.next(this._alerts);
  }

  get alerts() {
    return this._alerts;
  }

  public supportedChannels: ISupportedChannel[];

  constructor(config: any) {
    // TODO: remove this after all channels implement setSupportedChannels
    this.supportedChannels = [
      {
        channelType: CHANNEL_TYPES.Telephony,
        idName: '',
        id: ''
      }
    ];
    if ('name' in config && 'URL' in config.variables) {
      this.name = config.name;
      this.config = config;
      this.initializationCompleted = false;
      this.src = config.variables.URL;

      if ('Visible' in config.variables && config.variables.Visible === false) {
        this.visible = false;
        this.position = PLUGIN_POSITION.hidden;
      } else if ('position' in config.variables) {
        this.position = config.variables.position;
      } else if ('PluginType' in config.variables && config.variables.PluginType === 'globalControl') {
        this.position = PLUGIN_POSITION.top_right;
      } else {
        this.position = PLUGIN_POSITION.default;
      }

      if ('Layer' in config.variables) {
        this.layer = config.variables.Layer;
      } else {
        this.layer = 0;
      }

      if ('Icon' in config.variables) {
        this.icon = config.variables.Icon;
      }
    } else {
      throw new TypeError('Invalid configuration, no URL or no name! config=' + JSON.stringify(config));
    }
  }

  addAlert() {
    this.alerts++;
  }
}
