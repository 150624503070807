<div *ngIf="isSAMLPolling">
  <div class="loginInProgress">
    <div class="logo-light">
      <img src='{{ "assets/images/" + logoName }}' alt="DaVinci Logo">
    </div>
    <div style="text-align: center;">
      <div class="backToLoginScreen" (click)="cancelSAMLLogin()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <label tabindex="0" role="link">Cancel, or try again</label>
      </div>
    </div>
    <div class="loginUsingAuthProfileText">
      <mat-label class="overflow">Please complete logging in to continue</mat-label>
    </div>
    <div class="spinner-container">
      <img class="spinner" src="./../../assets/images/view_progress.gif" />
    </div>
  </div>
</div>

<div *ngIf="!isSAMLPolling">
  <div class="top" *ngIf="!showLoginAuthProfileScreen">
    <mat-card>
      <mat-card-title>
        <div class="logo">
          <img src='{{ "assets/images/" + logoName }}' alt="">
        </div>
      </mat-card-title>
      <form #createForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field color="accent">
          <input matInput placeholder="Username" [attr.disabled]="inProgress ? 'disabled' : null" [formControl]="Username"
            required>
          <mat-error *ngIf="Username.invalid">{{"Invalid username!"}}</mat-error>
        </mat-form-field>

        <mat-progress-bar color="accent" mode="indeterminate" *ngIf="inProgress"></mat-progress-bar>

        <button mat-button class="mat-raised-button mat-accent" [disabled]="inProgress" type="submit">Login</button>
      </form>
    </mat-card>
  </div>
  <div *ngIf="showLoginAuthProfileScreen">
    <app-login-auth-profile [userName]="Username.value"  (backAction) = "showLoginScreen()"></app-login-auth-profile>
  </div>
</div>
