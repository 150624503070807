<div *ngIf="isVisible" id="keypadMainContainerId" style="position: relative;">
  <div id="ProgressDivIdkeypadMainContainerId" style="
      top: 0;
      left: 0;
      position: absolute;
      z-index: 11;
      background: white;
      opacity: 0.7;
      filter: alpha(opacity=70);
      width: 100%;
      height: 100%;
      display: none;
    ">
    <input id="ProgressImageDivIdkeypadMainContainerId" style="position: absolute; top: -40%; left: 40%;" type="image"
      src="/assets/images/view_progress.gif" />
  </div>
  <div id="ProgressDispDivIdkeypadMainContainerId" style="
      top: 0;
      left: 0;
      position: absolute;
      z-index: 12;
      background: white;
      opacity: 0.7;
      filter: alpha(opacity=70);
      width: 100%;
      height: 100%;
      display: none;
    ">
    <input id="ProgressImageDispDivIdkeypadMainContainerId" style="position: absolute; top: -40%; left: 40%;"
      type="image" src="/assets/images/view_progress.gif" />
  </div>
  <div id="ReservedModekeypadMainContainerId" style="
      top: 0;
      left: 0;
      position: absolute;
      z-index: 13;
      background: white;
      opacity: 0.7;
      filter: alpha(opacity=70);
      width: 100%;
      height: 100%;
      display: none;
    "></div>

  <div id="kaypadContainerId" style="position: relative;">
    <div id="searchDialContainer">
      <div id="contextuaTextboxDiv">
        <div id="selectedPluginDiv">
          <img id="searchKeypadSymbolImage" src="/assets/images/voice_dialpad_normal.png" title="Dialpad" role="button"
            tabindex="0" aria-label="DialPad" (keyup)="toggleShowKeypadKey($event)" (click)="toggleShowKeypad()" />
        </div>
        <input id="searchTextField" [autocomplete]="enableDialpadAutocomplete ? 'on' : 'off'"
          aria-label="Number to Dial" title="Number to Dial" [class]="
            'searchTextField right-padding-' +
            (hasOperation()
              ? 2
              : selectedChannelApp != null &&
                selectedChannelApp.supportedChannels != null
              ? selectedChannelApp.supportedChannels.length
              : 1)
          " type="text" [(ngModel)]="searchText" (keydown)="onKeydown($event)" (focus)="onSearchFocus()"
          (blur)="onSearchBlur()" />
        <ng-container *ngIf="!hasOperation()">
          <ng-container *ngFor="let supportedChanel of selectedChannelApp.supportedChannels">
            <div id="searchKeypadDialDiv" [title]="getChannelTitle(supportedChanel.channelType)"
              (click)="onDialClicked(null, supportedChanel.channelType)">
              <img id="searchKeypadDialImage" role="button" tabindex="0" aria-label="Dial number" class="keyPadImage"
                [src]="getIcon(supportedChanel.channelType)" />
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="hasOperation()">
          <div *ngIf="getCurrentOpConfig().cancel" id="contextCancelDiv" [title]="getCurrentOpConfig().cancel"
            (click)="cancel()" (keyup)="cancelKeyup($event)">
            <img id="contextCancelImage" tabindex="0" role="button" aria-label="cancel transfer"
              src="/assets/images/searchdial_normal.png" [src]="getCurrentOpConfig().cancelImage" />
            <div *ngIf="getCurrentOpConfig().keypadDial" id="searchKeypadDialDiv"
              [title]="getCurrentOpConfig().keypadDial" (click)="onDialClicked()" (keyup)="onDialClickedKeyup($event)">
              <img id="searchKeypadDialImage" role="button" tabindex="0" aria-label="Dial number"
                class="keypadDialtransfer" src="/assets/images/searchdial_normal.png" />
            </div>
          </div>
        </ng-container>
      </div>
      <div [style.max-height.px]="livePresenceMaxHeight" class="contactList"
        *ngIf="visibleContacts.length > 0 && showContactList">
        <ng-container *ngFor="let contact of visibleContacts; let i = index">
          <span *ngIf="contact.presenceColor || contact.presence"
            [style.backgroundColor]="contact.presenceColor || 'gray'" [title]="contact.presence"
            [style.grid-row]="sanitizeStyle(i + 1 + ' /  auto')" (click)="onContactClicked(contact)" [className]="
              contact.channels && contact.channels.length > 0
                ? 'presence'
                : 'presence clickable'
            "></span>
          <span [style.grid-row]="sanitizeStyle(i + 1 + ' /  auto')" [matTooltip]="getNameToolTip(contact)"
            matTooltipShowDelay="30" matTooltipHideDelay="0" matTooltipClass="livePresenceTooltipClass"
            (click)="onContactClicked(contact)" [className]="
              contact.channels && contact.channels.length > 0
                ? 'name'
                : 'name clickable'
            ">
            {{
            contact.firstName && contact.lastName
            ? contact.firstName + " " + contact.lastName
            : contact.firstName || contact.lastName || contact.uniqueId
            }}
          </span>
          <span *ngIf="contact.groupName" [style.grid-row]="sanitizeStyle(i + 1 + ' /  auto')"
            [title]="contact.groupName" (click)="onContactClicked(contact)" [className]="
              contact.channels && contact.channels.length > 0
                ? 'group-name'
                : 'group-name clickable'
            ">{{ contact.groupName }}</span>
          <div [class]="contact.showMore ? 'channels maximize' : 'channels'"
            [style.grid-row]="sanitizeStyle(i + 1 + ' /  auto')" (click)="onContactClicked(contact)" [className]="
              contact.channels && contact.channels.length > 0
                ? false
                : 'clickable'
            ">
            <div class="channel" *ngFor="let channel of contact.channels" (click)="onContactClicked(contact, channel)">
              <img [src]="getIcon(channel.channelType)" [title]="channel.idName + ': ' + channel.id" />
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div id="KeypadId" class="Keypad" [style.visibility]="showKeypad ? 'visible' : 'hidden'"
      [style.height]="showKeypad ? 'inherit' : '0'">
      <div>
        <div>
          <input id="keypadKey1Id" class="KeypadButton" type="image" value="1" role="button" tabindex="0"
            aria-label="keypad Key 1" src="/assets/images/1.png" (click)="appendToSearch('1')" />
          <input id="keypadKey2Id" class="KeypadButton" type="image" value="2" role="button" tabindex="0"
            aria-label="keypadKey2" src="/assets/images/2.png" (click)="appendToSearch('2')" />
          <input id="keypadKey3Id" class="KeypadButton" type="image" value="3" role="button" tabindex="0"
            aria-label="keypad Key 3" src="/assets/images/3.png" (click)="appendToSearch('3')" />
        </div>
        <div>
          <input id="keypadKey4Id" class="KeypadButton" type="image" value="4" role="button" tabindex="0"
            aria-label="keypad Key 4" src="/assets/images/4.png" (click)="appendToSearch('4')" />
          <input id="keypadKey5Id" class="KeypadButton" type="image" value="5" role="button" tabindex="0"
            aria-label="keypad Key 5" src="/assets/images/5.png" (click)="appendToSearch('5')" />
          <input id="keypadKey6Id" class="KeypadButton" type="image" value="6" role="button" tabindex="0"
            aria-label="keypad Key 6" src="/assets/images/6.png" (click)="appendToSearch('6')" />
        </div>
        <div>
          <input id="keypadKey7Id" class="KeypadButton" type="image" value="7" role="button" tabindex="0"
            aria-label="keypad Key 7" src="/assets/images/7.png" (click)="appendToSearch('7')" />
          <input id="keypadKey8Id" class="KeypadButton" type="image" value="8" role="button" tabindex="0"
            aria-label="keypad Key 8" src="/assets/images/8.png" (click)="appendToSearch('8')" />
          <input id="keypadKey9Id" class="KeypadButton" type="image" value="9" role="button" tabindex="0"
            aria-label="keypad Key 9" src="/assets/images/9.png" (click)="appendToSearch('9')" />
        </div>
        <div>
          <input id="keypadKeyStarId" class="KeypadButton" type="image" value="*" role="button" tabindex="0"
            aria-label="keypad Key *" src="/assets/images/star.png" (click)="appendToSearch('*')" />
          <input id="keypadKey0Id" class="KeypadButton" type="image" value="0" role="button" tabindex="0"
            aria-label="keypad Key 0" src="/assets/images/0.png" (click)="appendToSearch('0')" />
          <input id="keypadKeyPoundId" class="KeypadButton" type="image" value="#" role="button" tabindex="0"
            aria-label="keypad Key #" src="/assets/images/pound.png" (click)="appendToSearch('#')" />
        </div>
      </div>
    </div>
  </div>
</div>