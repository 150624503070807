<div (window:scroll)="scrollFunction($event)"
     [className]="scrolledToTop ? 'notification-panel' : 'notification-panel-top'"
     *ngIf="notificationMessages.length === 1 && !isExpanded"
     (click)="isExpanded = !isExpanded">
  <div class="alertIconArea">
    <mat-icon
      *ngIf="isError(this.notificationMessages[0].notificationType)"
      class="errorIcon not-clickable-icon"
      >cancel</mat-icon
    >

    <mat-icon
      *ngIf="isWarning(this.notificationMessages[0].notificationType)"
      class="warningIcon not-clickable-icon"
      >info</mat-icon
    >

    <mat-icon
      *ngIf="isInformation(this.notificationMessages[0].notificationType)"
      class="infoIcon not-clickable-icon"
      >info</mat-icon
    >
  </div>
  <div class="notificationMessageArea">
    <label class="notificationLabel" title="{{ notificationMessages[0].message }}"> {{ notificationMessages[0].message }}</label>
  </div>

  <div class="clearNotificationArea">
    <mat-icon class="clearAlertImg clickable-icon" aria-hidden="false" tabindex="0" role="button" aria-label="close alert button" (keyup)="clearNotificationsKey($event)" (click)="clearNotifications()">close</mat-icon>
  </div>
</div>
<div (window:scroll)="scrollFunction($event)"
     [className]="scrolledToTop ? 'notification-panel-expanded' : 'notification-panel-top-expanded'"
     *ngIf="notificationMessages.length === 1 && isExpanded">
  <!-- Control Area -->
  <div class="controlArea-expanded">

    <!-- Left Control Area -->
    <div class="controlArea-expanded-left controlArea-segment">
      <div class="alertIconArea-expanded">
        <mat-icon
          *ngIf="isError(this.notificationMessages[0].notificationType)"
          class="errorIcon not-clickable-icon"
          >cancel</mat-icon
        >

        <mat-icon
          *ngIf="isWarning(this.notificationMessages[0].notificationType)"
          class="warningIcon not-clickable-icon"
          >info</mat-icon
        >

        <mat-icon
          *ngIf="isInformation(this.notificationMessages[0].notificationType)"
          class="infoIcon not-clickable-icon"
          >info</mat-icon
        >
      </div>
    </div>

    <!-- Center Control Area -->
    <div class="controlArea-expanded-center controlArea-segment"
         (click)="isExpanded = !isExpanded">
      <mat-icon class="clickable-icon">
        keyboard_arrow_up
      </mat-icon>
    </div>

    <!-- Right Control Area -->
    <div class="controlArea-expanded-right controlArea-segment">
      <div class="clearNotificationArea-expanded">
        <mat-icon class="clearAlertImg clickable-icon" aria-hidden="false" tabindex="0" role="button" aria-label="close alert button" (keyup)="clearNotificationsKey($event)" (click)="clearNotifications()">close</mat-icon>
      </div>
    </div>
  </div>

  <div class="notificationMessageArea-expanded">
    <label class="notificationLabel-expanded"
              [title]="notificationMessages[0].message">
              {{notificationMessages[0].message}}

              <mat-icon class="copyIcon clickable-icon" (click)="copyMessageToClipboard()" *ngIf="canCopyToClipboard && !isCopied">
                file_copy
              </mat-icon>
              <mat-icon class="copyIcon not-clickable-icon" (click)="copyMessageToClipboard()" *ngIf="canCopyToClipboard && isCopied">
                check_circle
              </mat-icon>
    </label>
  </div>
</div>
