import { Injectable, Inject } from '@angular/core';
import { IConfiguration } from '../model/IConfiguration';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public configData$ = new ReplaySubject<IConfiguration>(1);

  private readonly configUrlPath: string = 'ClientConfiguration';
  private configData: IConfiguration;

  constructor(private http: HttpClient) {}

  async loadConfigurationData(): Promise<IConfiguration> {
    this.configData = await this.http
      .get<IConfiguration>(`${window.location.origin}/${this.configUrlPath}`, {
        withCredentials: true
      })
      .toPromise();
    this.configData$.next(this.configData);
    this.configData$.complete();
    return this.configData;
  }

  get config(): IConfiguration {
    return this.configData;
  }
}
