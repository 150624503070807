<div *ngIf="!showLogin">
  <div class="loginInProgress">
    <div class="logo-light">
      <img src='{{ "assets/images/" + logoName }}' alt="DaVinci Logo">
    </div>
    <div style="text-align: center;">
      <div *ngIf="userName" class="usernameDiv">
        <label class="usernameLabel" [title]="userName">{{ userName }}</label>
      </div>
      <div class="backToLoginScreen" (click)="cancelSAMLLogin()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <label tabindex="0" role="link">Cancel, or try again</label>
      </div>
    </div>
    <div  class="loginUsingAuthProfileText" [matTooltip]="currentAuthInfoName"
      matTooltipShowDelay="30" matTooltipHideDelay="0" matTooltipPosition="after">

      <mat-label class="overflow">Please complete logging in to continue</mat-label>
    </div>
    <div class="spinner-container">
      <img class="spinner" src="./../../assets/images/view_progress.gif" />
    </div>
  </div>
</div>
<div class="top" *ngIf="showLogin">
  <mat-card>
    <div class="logo-light">
      <img src='{{ "assets/images/" + logoName }}' alt="">
    </div>

    <div class="loginUsingText">
      <mat-label>Login Using</mat-label>
    </div>
    <div *ngIf="showLogin" (click)="SAMLLogin()">
      <button mat-button class="mat-raised-button">
        <img *ngIf="authInfo.image && (authInfo.image !== '')" class="loginIcon" [src]="authInfo.image" />
        <label> {{authInfo.name}}</label>
      </button>

    </div>
  </mat-card>


</div>
