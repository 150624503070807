/**
 * @packageDocumentation
 * @module DaVinci_API
 */

import { CHANNEL_TYPES } from './ChannelTypes';
import { CONTEXTUAL_OPERATION_TYPE } from '../AmcApi';

/**
 * A channel that the app supports with the relevant info for the current user
 *
 */
export interface ISupportedChannel {
  channelType: CHANNEL_TYPES;
  idName: string;
  id: string;
  /**
   * Optional list of operation you which for this channel to show up on.
   */
  validOperations?: CONTEXTUAL_OPERATION_TYPE[];
  /**
   * Optiona list of presences. Your channels will only show when the user is set to a prsence here.
   */
  validPresences?: string[];
  /**
   * Any additional info you wish to pass about the channel.
   */
  customValues?: { [key: string]: any };
}
