import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from '../../services/logger.service';
import { BaseAuthInfo } from 'src/app/model/auth/BaseAuthInfo';
import { AMCValidators } from 'src/app/model/Validators/AMCValidators';
import { getUrlParameterByName } from '../../api/HelperFunctions';
import { StorageService } from 'src/app/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  Password = new FormControl('', [Validators.required, AMCValidators.cannotContainSpace]);

  Username = new FormControl('', [Validators.required, Validators.email]);
  inProgress = false;
  error = false;
  showLoginAuthProfileScreen = false;
  logoName: string;
  isSAMLPolling: boolean;
  samlWindow: WindowProxy;

  constructor(
    private api: ApiService,
    private _router: Router,
    private ps: NgxPermissionsService,
    private snackBar: MatSnackBar,
    private loggerService: LoggerService
  ) {
    this.isSAMLPolling = false;
    this.logoName = StorageService.getLogoPath();
    this.samlWindow = null;
  }

  async ngOnInit() {
    this.loadPreLoginScript();
    if (window.sessionStorage.getItem('samlJwtToken') && window.sessionStorage.getItem('appJwtToken')) {
      this.isSAMLPolling = true;
      this.getToken();
    }
  }

  showLoginScreen() {
    this.showLoginAuthProfileScreen = false;
  }

  cancelSAMLLogin() {
    this.isSAMLPolling = false;
    this.api.stopSAMLPolling();
    window.sessionStorage.removeItem('appJwtToken');
    window.sessionStorage.removeItem('samlJwtToken');

    if (this.samlWindow) {
      this.samlWindow.close();
      this.samlWindow = null;
    }
  }

  // Be able to add other scripts onto the same section
  // Remove the all loaded scripts up on login on Home component

  async loadPreLoginScript() {
    try {
      const scriptURL = getUrlParameterByName('preloginJSURL');
      if (scriptURL) {
        let promise: Promise<void>;
        if (scriptURL) {
          promise = new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            document.createElement('script');
            script.src = scriptURL;
            script.setAttribute('data-name', 'preloginscript');
            script.type = 'application/javascript';
            const timeout = window.setTimeout(() => {
              reject('Failed to load script! url=' + scriptURL);
            }, 50000);
            script.onload = () => {
              window.clearTimeout(timeout);
              resolve();
            };
            document.head.appendChild(script);
          });
        }

        await promise;
      }
    } catch (e) {
      console.log('Error handling prelogin loadScript operation! Error=' + e.message);
    }
  }

  async onSubmit() {
    this.inProgress = true;
    try {
      if (this.Username.valid) {
        await this.api.triggerUpdateToLoginAuthInfoSubscriber(this.Username.value).then(
          (authInfos: BaseAuthInfo[]) => {
            if (authInfos && authInfos.length !== 0) {
              this.error = false;
              this.showLoginAuthProfileScreen = true;
            } else {
              this.error = true;
              this.showLoginAuthProfileScreen = false;
            }
          },
          () => {
            this.error = true;
            this.showLoginAuthProfileScreen = false;
            const snackBarRef = this.snackBar.open('Username not found!', 'DISMISS');
          }
        );
      }
    } catch (e) {
      this.loggerService.logger.logTrace(`LoginComponent -OnSubmit error: ${JSON.stringify(e)}`);
      const snackBarRef = this.snackBar.open('Username not found!', 'DISMISS');
    } finally {
      this.inProgress = false;
    }
  }

  private getToken() {
    try {
      this.loggerService.logger.logDebug('Polling for access token with SAML and app token from session storage');

      if (sessionStorage.getItem('samlJwtToken') && sessionStorage.getItem('appJwtToken')) {
        this.api.stopSAMLPolling();
        const startTime = new Date().getTime();
        this.api
          .startSAMLPolling({
            samlJwtToken: sessionStorage.getItem('samlJwtToken'),
            appJwtToken: sessionStorage.getItem('appJwtToken')
          })
          .subscribe(
            (result) => {
              if (result) {
                this.loggerService.logger.logDebug('Polling for SAML access token successful');

                window.localStorage.setItem('SAMLLoginStamp', new Date().toString());
                window.sessionStorage.removeItem('appJwtToken');
                window.sessionStorage.removeItem('samlJwtToken');
                this.isSAMLPolling = false;

                window.location.reload();
              }
            },
            (error) => {
              this.loggerService.logger.logError(`Polling for SAML access token failed with exception : ${JSON.stringify(error)}`);

              window.sessionStorage.removeItem('appJwtToken');
              window.sessionStorage.removeItem('samlJwtToken');
              this.isSAMLPolling = false;

              if (this.samlWindow) {
                this.samlWindow.close();
                this.samlWindow = null;
              }

              window.location.reload();
            },
            () => {
              this.loggerService.logger.logDebug('polling for SAML access token completed');

              if (new Date().getTime() - startTime >= this.api.samlPollTimeoutSeconds * 1000) {
                this.loggerService.logger.logError('Polling for SAML access token timed out');
                this.snackBar.open('Login attempt timed out, please try again', 'DISMISS');
              }

              this.isSAMLPolling = false;
              this.cancelSAMLLogin();
            }
          );
      } else {
        this.cancelSAMLLogin();
        this.snackBar.open('SSO login failed, please try again', 'DISMISS');
      }
    } catch (e) {
      this.loggerService.logger.logError(`SAML Login failed with exception : ${JSON.stringify(e)}`);
      this.cancelSAMLLogin();
      this.snackBar.open('SSO login failed, please try again', 'DISMISS');
    }
  }
}
