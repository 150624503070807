import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DaVinciApp as DaVinciPlugin } from '../../model/Plugin';
import { PLUGIN_POSITION } from '../../model/PluginPosition';
import { IPanelItem } from '../../model/IPanelItem';
import { INotificationMessage } from '../../api/AmcApi';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelComponent {
  private _plugins: {
    [pluginPosition: string]: DaVinciPlugin[];
  };

  @Input() width: string;
  @Input() appLogoUrl: string;

  @Input() set plugins(value) {
    this._plugins = value;
    for (const key in this._plugins) {
      if (this._plugins.hasOwnProperty(key)) {
        this._plugins[key].sort((a, b) => {
          if (a.config.variables.hasOwnProperty('PositionSequenceNumber') && b.config.variables.hasOwnProperty('PositionSequenceNumber')) {
            return a.config.variables.PositionSequenceNumber - b.config.variables.PositionSequenceNumber;
          }
          if (a.config.variables.hasOwnProperty('PositionSequenceNumber')) {
            return -1;
          }
          if (b.config.variables.hasOwnProperty('PositionSequenceNumber')) {
            return 1;
          }
          return 0;
        });
      }
    }
    this.panelItems = {};
  }

  @Input() notificationMessages: INotificationMessage[];

  @Input() isBottomLayer = true;

  get plugins() {
    return this._plugins;
  }

  panelItems: {
    [itemPosition: string]: IPanelItem[];
  } = {};

  showVersionsOverlay = false;

  constructor(private loggerService: LoggerService) {}

  getTopLeftItems(): IPanelItem[] {
    if (!this.panelItems['TopLeft']) {
      this.panelItems['TopLeft'] = [
        {
          type: 'ConstantSize',
          params: {
            height: '25px',
            children: this.plugins[PLUGIN_POSITION.top_left].map((plugin) => ({
              type: 'DaVinciApp',
              params: plugin
            }))
          }
        }
      ];
    }
    return this.panelItems['TopLeft'];
  }

  getTopBannerItems(): IPanelItem[] {
    if (!this.panelItems['TopBanner']) {
      this.panelItems['TopBanner'] = [
        {
          type: 'ConstantSize',
          params: {
            // potentially add height or width
            width: '100%',
            // height: '20px',
            children: this.plugins[PLUGIN_POSITION.top_banner].map((plugin) => ({
              type: 'DaVinciApp',
              params: plugin
            }))
          }
        }
      ];
    }
    return this.panelItems['TopBanner'];
  }

  getDialPadItems(): IPanelItem[] {
    if (!this.panelItems['DialPad']) {
      this.panelItems['DialPad'] = [
        {
          type: 'ConstantSize',
          params: {
            width: '100%',
            children: this.plugins[PLUGIN_POSITION.dial_pad].map((plugin) => ({
              type: 'DaVinciApp',
              params: plugin
            }))
          }
        }
      ];
    }
    return this.panelItems['DialPad'];
  }

  getTopRightItems(): IPanelItem[] {
    if (!this.panelItems['TopRight']) {
      this.panelItems['TopRight'] = [
        {
          type: 'ConstantSize',
          params: {
            height: '25px',
            children: this.plugins[PLUGIN_POSITION.top_right].map((plugin) => ({
              type: 'DaVinciApp',
              params: plugin
            }))
          }
        }
      ];
    }
    return this.panelItems['TopRight'];
  }

  getBottomLeftItems(): IPanelItem[] {
    if (!this.panelItems['BottomLeft']) {
      this.panelItems['BottomLeft'] = this.plugins[PLUGIN_POSITION.bottom_left].map((plugin) => ({ type: 'DaVinciApp', params: plugin }));
    }
    return this.panelItems['BottomLeft'];
  }

  getBottomRightItems(): IPanelItem[] {
    if (!this.panelItems['BottomRight']) {
      this.panelItems['BottomRight'] = this.plugins[PLUGIN_POSITION.bottom_right].map((plugin) => ({ type: 'DaVinciApp', params: plugin }));
    }
    return this.panelItems['BottomRight'];
  }

  getMiddleItems(): IPanelItem[] {
    if (!this.panelItems['Middle']) {
      this.panelItems['Middle'] = [
        {
          type: 'TabbedItems',
          params: this.plugins[PLUGIN_POSITION.center].concat(this.plugins[PLUGIN_POSITION.default])
        },
        ...this.plugins[PLUGIN_POSITION.center_no_tab].map((plugin) => ({
          type: 'DaVinciApp',
          params: plugin
        })),
        {
          type: 'ConstantSize',
          params: {
            height: '0px',
            children: this.plugins[PLUGIN_POSITION.hidden].map((plugin) => ({
              type: 'DaVinciApp',
              params: plugin
            }))
          }
        }
      ];
    }
    return this.panelItems['Middle'];
  }

  getCRMPanelPlugins() {
    return this.plugins[PLUGIN_POSITION.crm_panel];
  }
}
