import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { IPanelItem } from '../../../model/IPanelItem';

@Component({
  selector: 'app-panel-item',
  templateUrl: './panel-item.component.html',
  styleUrls: ['./panel-item.component.css']
})
export class PanelItemComponent {
  @Input() item: IPanelItem;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
}
