import { Component, OnInit, ChangeDetectorRef, HostListener, Renderer2 } from '@angular/core';
import { IContextualContact, CONTEXTUAL_OPERATION_TYPE, IContextualContactChannel } from '../../api/models/ContextualOperation';
import { IResponse, IRequest } from '../../api/HelperFunctions';
import { OFHelper } from '../../services/OFHelper';
import { ContextualContactsService, IPluginContacts } from '../../services/contextual-contacts.service';
import { OPERATIONS, CHANNEL_TYPES } from '../../api/AmcApi';
import { setTimeout } from 'src/util/timeout';
import { ConfigurationService } from '../../services/configuration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IContextualOperation } from '../../model/IContextualOperation';
import { DaVinciApp } from '../../model/Plugin';
import { SecurityContext } from '@angular/core';

@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss']
})
export class KeypadComponent implements OnInit {
  showContactList = false;
  showKeypad = false;
  protected livePresenceMaxHeight: number;
  private liveHeightRatio: number;
  protected enableDialpadAutocomplete: boolean;
  isVisible = false;
  private _searchText = '';
  get searchText() {
    return this._searchText;
  }

  // Add the @HostListener decorator to listen for window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    // Update the maxHeight value when the window resizes
    this.updateLivePresenceHeight();
  }

  set searchText(val: string) {
    this._searchText = val;
    this.updateSearch();
  }

  opConfigs = {
    [CONTEXTUAL_OPERATION_TYPE.DTMF]: {
      cancelImage: 'assets/images/voice_dtmf_cancel.png',
      keypadDial: false,
      cancel: 'Cancel DTMF'
    },
    [CONTEXTUAL_OPERATION_TYPE.BlindTransfer]: {
      cancelImage: 'assets/images/voice_blindtransfer_cancel.png',
      keypadDial: 'Dial Blind Transfer',
      cancel: 'Cancel Blind Transfer'
    },
    [CONTEXTUAL_OPERATION_TYPE.WarmTransfer]: {
      cancelImage: 'assets/images/voice_warmtransfer_cancel.png',
      keypadDial: 'Dial Warm Transfer',
      cancel: 'Cancel Warm Transfer'
    },
    [CONTEXTUAL_OPERATION_TYPE.Conference]: {
      cancelImage: 'assets/images/voice_conference_cancel.png',
      keypadDial: 'Dial Conference',
      cancel: 'Cancel Conference'
    },
    [CONTEXTUAL_OPERATION_TYPE.Consult]: {
      cancelImage: 'assets/images/voice_conference_cancel.png',
      keypadDial: 'Dial Consult',
      cancel: 'Cancel Consult'
    },
    [CONTEXTUAL_OPERATION_TYPE.Other]: {
      cancelImage: 'assets/images/voice_blindtransfer_cancel.png',
      keypadDial: 'Dial',
      cancel: 'Cancel'
    },
    noop: {
      cancelImage: '/assets/images/searchdial_normal.png',
      keypadDial: 'Dial',
      cancel: false
    }
  };

  plugins: IPluginContacts = {};
  selectedPlugin: string = null;
  visibleContacts: IExtendedContextualContact[] = [];
  sharedContacts: IContextualContact[] = [];
  expandUrl: string;
  collapseUrl: string;
  selectedContact: IContextualContact;
  selectedChannel: IContextualContactChannel;
  private operationFilter: CONTEXTUAL_OPERATION_TYPE[] = [];
  selectedChannelApp: DaVinciApp;

  constructor(
    private contextualContactsService: ContextualContactsService,
    private configurationService: ConfigurationService,
    private changeDetectorRef: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private ofHelper: OFHelper,
    private _: Renderer2
  ) {
    this.enableDialpadAutocomplete = true;
  }

  sanitizeStyle(text: string) {
    const result = this.domSanitizer.sanitize(SecurityContext.STYLE, text);
    return result;
  }

  updateLivePresenceHeight() {
    this.livePresenceMaxHeight = Math.ceil(this.liveHeightRatio * (window.innerHeight - 50));
  }

  ngOnInit() {
    OFHelper.selectedChannelApp$.subscribe((plugin) => (this.selectedChannelApp = plugin));
    OFHelper.populateDialpad$.subscribe((value) => (this.searchText += value));
    // Should fix the issue of adding the number many times by mistake.
    OFHelper.replaceDialpad$.subscribe((value) => (this.searchText = value));
    OFHelper.supportedChannelsUpdated$.subscribe(() => this.changeDetectorRef.detectChanges());
    this.contextualContactsService.contactsByApp$.subscribe((val) => {
      this.plugins = val;
      if (this.plugins[this.selectedPlugin] != null) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
      this.updateSearch();
    });
    this.ofHelper.globalConfiguration$.subscribe((conf) => {
      if (conf.LivePresenceHeightRatio) {
        this.liveHeightRatio = Math.min(0.8, Math.max(0.1, conf.LivePresenceHeightRatio / 100));
        this.updateLivePresenceHeight();
      }
      this.enableDialpadAutocomplete = conf.hasOwnProperty('EnableDialpadAutocomplete') ? conf.EnableDialpadAutocomplete : true;
      // Convert operation filters to enum. Remove any invalid ones
      this.operationFilter = conf.LivePresenceFilterOnOperation.map((type) => CONTEXTUAL_OPERATION_TYPE[type]).filter((type) => type !== undefined);
    });
    this.ofHelper.contextualOperation$.subscribe((op) => this.handleContextualOperation(op));
    this.ofHelper.onSpeedDial$.subscribe((event) => this.onSpeedDial(event));
    this.contextualContactsService.selectedApp$.subscribe((appName) => {
      this.selectedPlugin = appName;
      if (this.plugins[appName] != null) {
        this.isVisible = true;
        this.updateSearch();
      } else {
        this.isVisible = false;
      }
      this.changeDetectorRef.detectChanges();
    });
    this.contextualContactsService.sharedContacts$.subscribe((contacts) => {
      this.sharedContacts = contacts;
      this.updateSearch();
    });

    this.expandUrl = this.configurationService.config.iconPack + 'section_expand.png';
    this.collapseUrl = this.configurationService.config.iconPack + 'section_collapse.png';
  }

  getIcon(channelType: CHANNEL_TYPES) {
    switch (channelType) {
      case CHANNEL_TYPES.SMS:
      case CHANNEL_TYPES.Chat:
        return this.configurationService.config.iconPack + 'sms_initiate.png';
      case CHANNEL_TYPES.Email:
        return this.configurationService.config.iconPack + 'email.png';
      case CHANNEL_TYPES.Telephony:
        return this.configurationService.config.iconPack + 'Dial.png';
      case CHANNEL_TYPES.Email:
        return this.configurationService.config.iconPack + 'email.png';
      case CHANNEL_TYPES.Lead:
        return this.configurationService.config.iconPack + 'lead.png';
      default:
        return this.configurationService.config.iconPack + 'default.png';
    }
  }

  onSearchFocus() {
    this.showContactList = true;
    this.contextualContactsService.updateSharedContacts();
    this.changeDetectorRef.detectChanges();
  }

  async onSearchBlur() {
    /**
     * This set timeout is kinda of hackish.
     * It is here because we wont get click events(onContactClicked) if we hide the element immediately
     * The problem is that this is a timing issue and we could receive a blur event without a contact clicked event
     * e.g. they click on something else
     * Therefore we cant just wait for the click event to happen
     */
    await setTimeout(300);
    this.showContactList = false;
    this.changeDetectorRef.detectChanges();
  }

  onSpeedDial(dialText: string) {
    this.searchText = dialText;
    this.onDialClicked();
  }

  handleContextualOperation(op: IContextualOperation) {
    const { appName, requestedOperation, requestedOperationMessage, channelType } = op;
    if (!(appName in this.plugins)) {
      const response: IResponse = {
        request: requestedOperationMessage,
        isResponse: true,
        reject: undefined,
        resolve: undefined
      };
      response.reject = 'Plugin not setup for contextual operations! Please call addContextualContacts!';
      this.ofHelper.sendMessageToPluginWithInterceptors(appName, response);
      return;
    }
    if (op.requestedOperation === CONTEXTUAL_OPERATION_TYPE.Cancel && this.plugins[op.appName].requestedOperation !== undefined) {
      this.cancelOperation(op.appName);
    } else {
      if (this.plugins[this.selectedPlugin].requestedOperation !== undefined) {
        const response: IResponse = {
          request: this.plugins[appName].requestedOperationMessage,
          isResponse: true,
          reject: 'Canceled by new contextual operation!',
          resolve: undefined
        };
        this.ofHelper.sendMessageToPluginWithInterceptors(appName, response);
        this.plugins[appName].requestedOperation = undefined;
        this.plugins[appName].requestedOperationMessage = undefined;
      }
      this.plugins[appName].requestedOperation = requestedOperation;
      this.plugins[appName].requestedOperationMessage = requestedOperationMessage;
      this.plugins[appName].requestedOperationChannelType = channelType;
      this.showKeypad = true;
      this.updateSearch();
      this.changeDetectorRef.detectChanges();
    }
  }

  getCurrentOpConfig() {
    if (this.plugins[this.selectedPlugin] != null && this.plugins[this.selectedPlugin].requestedOperation != null) {
      return this.opConfigs[this.plugins[this.selectedPlugin].requestedOperation];
    }
    return this.opConfigs.noop;
  }

  hasOperation() {
    return this.plugins[this.selectedPlugin] != null && this.plugins[this.selectedPlugin].requestedOperation != null;
  }

  appendToSearch(str: string) {
    this.searchText += str;
    if (this.plugins[this.selectedPlugin].requestedOperation && this.plugins[this.selectedPlugin].requestedOperation === CONTEXTUAL_OPERATION_TYPE.DTMF) {
      this.onDialClicked(str);
    }
  }

  toggleShowKeypad() {
    this.showKeypad = !this.showKeypad;
    this.changeDetectorRef.detectChanges();
  }

  toggleShowKeypadKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.toggleShowKeypad();
    }
  }

  updateSearch() {
    let contacts: IContextualContact[] = [];

    const hasOperation = this.plugins[this.selectedPlugin] != null && this.plugins[this.selectedPlugin].requestedOperation != null;
    const isOperationFilterDefined = this.operationFilter != null && this.operationFilter.length > 0;
    const isOperationFilterValid =
      !isOperationFilterDefined || (hasOperation && this.operationFilter.includes(this.plugins[this.selectedPlugin].requestedOperation));
    if (isOperationFilterValid) {
      // Remove any contact/channel that do not support the current contextual operation
      const sharedContacts = this.sharedContacts
        .map((contact) => ({
          ...contact,
          channels: contact.channels.filter(
            (channel) =>
              channel.validOperations == null ||
              channel.validOperations.length === 0 ||
              (hasOperation && channel.validOperations.includes(this.plugins[this.selectedPlugin].requestedOperation))
          )
        }))
        .filter((contact) => contact.channels != null && contact.channels.length > 0); // remove any contacts that dont have any valid channels
      contacts = sharedContacts;
    }

    if (this.plugins[this.selectedPlugin] != null) {
      // add plugin contacts if any
      contacts = [...this.plugins[this.selectedPlugin].contacts, ...contacts];
    }

    const searchWords = this.searchText
      .trim()
      .split(/\s+/)
      .map((word) => word.toLocaleLowerCase());
    if (this.searchText != null && this.searchText.length > 0) {
      contacts = contacts.filter((contact) => {
        let result = true;
        for (const word of searchWords) {
          if (
            (contact.firstName || '').toLocaleLowerCase().includes(word) ||
            (contact.lastName || '').toLocaleLowerCase().includes(word) ||
            (contact.uniqueId || '').toLocaleLowerCase().includes(word) ||
            (contact.groupName || '').toLocaleLowerCase().includes(word)
          ) {
            continue;
          } else {
            let foundInChannel = false;
            for (const channel of contact.channels) {
              if ((channel.id || '').toLocaleLowerCase().includes(word) || (channel.idName || '').toLocaleLowerCase().includes(word)) {
                foundInChannel = true;
                break;
              }
            }
            if (foundInChannel) {
              continue;
            } else {
              result = false;
              break;
            }
          }
        }
        return result;
      });
    }

    // Filter channels if contextual operation
    if (this.selectedPlugin && this.plugins[this.selectedPlugin] && this.plugins[this.selectedPlugin].requestedOperationChannelType) {
      contacts = contacts
        .map((contact) => ({
          ...contact,
          channels: contact.channels.filter(
            (channel) => channel.createdByApp === this.selectedPlugin && channel.channelType === this.plugins[this.selectedPlugin].requestedOperationChannelType
          )
        }))
        .filter((contact) => contact.channels.length > 0);
    }

    // Clear selected contact if search changes
    if (this.selectedContact != null) {
      if (
        !(
          (searchWords.length === 1 && searchWords[0] === this.selectedContact.uniqueId) ||
          (searchWords.length === 2 && searchWords[0] === this.selectedContact.firstName && searchWords[1] === this.selectedContact.lastName)
        )
      ) {
        this.selectedContact = null;
        this.selectedChannel = null;
      }
    }

    this.visibleContacts = contacts;

    this.changeDetectorRef.detectChanges();
  }

  onContactClicked(contact: IContextualContact, channel?: IContextualContactChannel) {
    if (channel == null && contact.channels && contact.channels.length > 0) {
      return; // Do nothing if the contact has a channel but they clicked on something else
    }

    this.showContactList = false;
    this.searchText =
      contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName || contact.lastName || contact.uniqueId;
    this.selectedContact = contact;
    this.selectedChannel = channel;
    this.onDialClicked();
    this.changeDetectorRef.detectChanges();
  }

  onContactShowMore(contact: IExtendedContextualContact) {
    contact.showMore = !contact.showMore;
    this.changeDetectorRef.detectChanges();
  }

  onKeydown(ev) {
    // if requestedOperation is DTMF allow only numbers
    if (this.plugins[this.selectedPlugin].requestedOperation && this.plugins[this.selectedPlugin].requestedOperation === CONTEXTUAL_OPERATION_TYPE.DTMF) {
      try {
        const regex = /[0-9\*\#]|\./;
        if (!regex.test(ev.key)) {
          ev.preventDefault();
        } else {
          this.onDialClicked(ev.key);
        }
      } catch (e) {}
      return;
    } else if (ev.which === 13) {
      this.onDialClicked();
    }
  }

  onDialClickedKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onDialClicked();
    }
  }

  // eslint-disable-next-line max-statements
  onDialClicked(dialText?: string, channelType?: CHANNEL_TYPES) {
    const inputText = dialText ? dialText : this.searchText;
    const isDTMFOperation =
      this.plugins[this.selectedPlugin].requestedOperation && this.plugins[this.selectedPlugin].requestedOperation === CONTEXTUAL_OPERATION_TYPE.DTMF;
    if (inputText === '') {
      return;
    }

    let contact: IContextualContact = {
      uniqueId: inputText,
      channels: []
    };
    if (this.selectedContact != null) {
      contact = this.selectedContact;
      if (this.selectedChannel) {
        contact.channels = [this.selectedChannel];
      }
    } else if (this.selectedPlugin && this.plugins[this.selectedPlugin]) {
      // Convoluted and possible quite bad way to find first match in contact list
      const searchWords = inputText
        .trim()
        .split(/\s+/)
        .map((word) => word.toLocaleLowerCase());
      for (const aContact of this.plugins[this.selectedPlugin].contacts) {
        for (const word of searchWords) {
          if (
            (aContact.firstName || '').toLocaleLowerCase().includes(word) ||
            (aContact.lastName || '').toLocaleLowerCase().includes(word) ||
            (aContact.uniqueId || '').toLocaleLowerCase().includes(word) ||
            (aContact.groupName || '').toLocaleLowerCase().includes(word)
          ) {
            contact = aContact;
            break;
          } else {
            let foundInChannel = false;
            for (const channel of aContact.channels) {
              if (channel.id.toLocaleLowerCase().includes(word) || channel.idName.toLocaleLowerCase().includes(word)) {
                foundInChannel = true;
                break;
              }
            }
            if (foundInChannel) {
              contact = aContact;
              break;
            }
          }
        }
      }
    }

    // Set displayName for backwards compatibility
    if (contact.channels.length === 1) {
      contact.displayName = contact.channels[0].id;
    } else {
      contact.displayName = contact.uniqueId;
    }

    if (this.plugins[this.selectedPlugin].requestedOperation !== undefined) {
      const response: IResponse = {
        request: this.plugins[this.selectedPlugin].requestedOperationMessage,
        isResponse: true,
        reject: undefined,
        resolve: contact
      };
      if (isDTMFOperation) {
        response['isPartialResponse'] = true;
      }
      this.ofHelper.sendMessageToPluginWithInterceptors(this.selectedPlugin, response);
      if (!isDTMFOperation) {
        this.plugins[this.selectedPlugin].requestedOperation = undefined;
        this.plugins[this.selectedPlugin].requestedOperationMessage = undefined;
      }
    } else if (this.selectedChannel != null) {
      // If no operation taking place and a channel is selected then send to the app of that channel
      const message: IRequest = {
        operation: OPERATIONS.CONTEXTUAL_EVENT,
        data: [contact]
      };
      this.ofHelper.sendMessageToPluginWithInterceptors(this.selectedChannel.createdByApp, message);
    } else {
      if (
        this.ofHelper['selectedChannelApp']['supportedChannels'].length === 1 &&
        this.ofHelper['selectedChannelApp']['supportedChannels'][0]['channelType'] === 8
      ) {
        channelType = CHANNEL_TYPES.SMS;
      }
      const message: IRequest = {
        operation: OPERATIONS.CONTEXTUAL_EVENT,
        data: [contact, channelType]
      };
      this.ofHelper.sendMessageToPluginWithInterceptors(this.selectedPlugin, message);
    }

    // Clear and Update UI only if the operation is not DTMF
    if (!isDTMFOperation) {
      this.searchText = '';
      this.plugins[this.selectedPlugin].requestedOperation = undefined;
      this.showContactList = false;
      this.showKeypad = false;
      this.selectedContact = null;
      this.selectedChannel = null;
      this.changeDetectorRef.detectChanges();
    }
  }

  cancelKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.cancel();
    }
  }

  cancel() {
    if (this.plugins[this.selectedPlugin].requestedOperation !== undefined) {
      const response: IResponse = {
        request: this.plugins[this.selectedPlugin].requestedOperationMessage,
        isResponse: true,
        reject: 'Canceled by user!',
        resolve: undefined
      };
      this.ofHelper.sendMessageToPluginWithInterceptors(this.selectedPlugin, response);
      this.searchText = '';
      this.plugins[this.selectedPlugin].requestedOperation = undefined;
      this.plugins[this.selectedPlugin].requestedOperationMessage = undefined;
      this.showKeypad = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  cancelOperation(appName: string) {
    if (this.plugins[appName].requestedOperation !== undefined) {
      const response: IResponse = {
        request: this.plugins[appName].requestedOperationMessage,
        isResponse: true,
        reject: 'Canceled by user!',
        resolve: undefined
      };

      this.ofHelper.sendMessageToPluginWithInterceptors(appName, response);
      this.searchText = '';
      this.plugins[appName].requestedOperation = undefined;
      this.plugins[appName].requestedOperationMessage = undefined;
      this.showKeypad = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  getNameToolTip(contact: IExtendedContextualContact) {
    let result = contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName || contact.lastName || '';

    if (contact.uniqueId) {
      if (result.length > 0) {
        result += '\n';
      }
      result += contact.uniqueId;
    }
    if (contact.presence) {
      if (result.length > 0) {
        result += '\n';
      }
      result += contact.presence;
    }
    if (contact.reason) {
      result += '|' + contact.reason;
    }
    return result;
  }

  getChannelTitle(type: CHANNEL_TYPES) {
    switch (type) {
      case CHANNEL_TYPES.Account:
        return 'Account';
      case CHANNEL_TYPES.Chat:
        return 'Start Chat';
      case CHANNEL_TYPES.Contact:
        return 'Contact';
      case CHANNEL_TYPES.Email:
        return 'Send Email';
      case CHANNEL_TYPES.Lead:
        return 'Lead';
      case CHANNEL_TYPES.Prospect:
        return 'Prospect';
      case CHANNEL_TYPES.SMS:
        return 'Send SMS';
      case CHANNEL_TYPES.Social:
        return 'Create Social Post';
      case CHANNEL_TYPES.Telephony:
      default:
        return 'Dial';
    }
  }
}

interface IExtendedContextualContact extends IContextualContact {
  showMore?: boolean;
}
