import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MouseService {
  private isMouseDown = false;
  public mouseMove = new Subject<MouseEvent>();
  public mouseDown = new Subject<MouseEvent>();
  public mouseUp = new Subject<MouseEvent>();

  constructor() {
    document.body.addEventListener('mousemove', (event) => this.mouseMove.next(event));
    document.body.addEventListener('mousedown', (event) => {
      if (event.button === 0) {
        this.isMouseDown = true;
        this.mouseDown.next(event);
      }
    });
    document.body.addEventListener('mouseup', (event) => {
      if (this.isMouseDown) {
        this.isMouseDown = false;
        this.mouseUp.next(event);
      }
    });
    document.body.addEventListener('mouseenter', (event) => {
      if (this.isMouseDown && event.buttons === 0) {
        this.isMouseDown = false;
        this.mouseUp.next(event);
      }
    });
  }
}
