/* eslint-disable prettier/prettier */
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { DaVinciApp } from '../../../model/Plugin';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IConfiguration } from 'src/app/model/IConfiguration';
import { AppsService } from '../../../services/apps.service';
import { ContextualContactsService } from '../../../services/contextual-contacts.service';
import { OFHelper } from '../../../services/OFHelper';
import { IRequest } from 'src/app/api/HelperFunctions';
import { OPERATIONS } from 'src/app/api/models/Operations';
import { SecurityContext } from '@angular/core';

@Component({
  selector: 'app-tabbed-items',
  templateUrl: './tabbed-items.component.html',
  styleUrls: ['./tabbed-items.component.scss']
})
export class TabbedItemsComponent {
  private _plugins: DaVinciApp[];
  private config: IConfiguration;

  @Input() set plugins(value) {
    this._plugins = value;
    if (this._plugins && this._plugins.length > 0) {
      this.selectedPlugin = 0;
    }
  }

  get plugins() {
    return this._plugins;
  }

  private _selectedPlugin: number;
  set selectedPlugin(value) {
    this.processSetPlugin(value);
  }

  registeredOperationsUpdated(event) {
    const allPlugins = OFHelper.plugins;
    let appsName = '';
    this.plugins.forEach((element) => {
      appsName += element.name + ',';
    });
    let speedDial = null;
    if (allPlugins.hasOwnProperty('Speed Dial')) {
      speedDial = allPlugins['Speed Dial'];
    } else if (allPlugins.hasOwnProperty('DAVINCI APP FOR SPEED DIAL')) {
      speedDial = allPlugins['DAVINCI APP FOR SPEED DIAL'];
    }

    const selectedPluginIndex = this._selectedPlugin;
    if (speedDial) {
      let selectedPluginName = null;
      if (selectedPluginIndex != null) {
        selectedPluginName = this.plugins[selectedPluginIndex].name;
      }
      const speedDialApps = event[OPERATIONS.CLICK_TO_DIAL];
      if (speedDialApps && selectedPluginName) {
        if (speedDialApps.includes(selectedPluginName)) {
          const message: IRequest = {
            operation: OPERATIONS.FRAMEWORK_NOTIFICATIONS,
            data: [{ shouldSpeedDialShow: true }]
          };
          this._OFHelper.sendMessageToPluginWithInterceptors(
            speedDial.name,
            message
          );
        } else {
          const message: IRequest = {
            operation: OPERATIONS.FRAMEWORK_NOTIFICATIONS,
            data: [{ shouldSpeedDialShow: false }]
          };
          this._OFHelper.sendMessageToPluginWithInterceptors(
            speedDial.name,
            message
          );
        }
      }
    }

    if (selectedPluginIndex != null && event.hasOwnProperty(OPERATIONS.FRAMEWORK_NOTIFICATIONS)) {
      const message: IRequest = {
        operation: OPERATIONS.FRAMEWORK_NOTIFICATIONS,
        data: [
          {
            didTabChange: true,
            apps: appsName,
            newTabName: this.plugins[selectedPluginIndex].name
          }
        ]
      };

      for (let i = 0; i < event[OPERATIONS.FRAMEWORK_NOTIFICATIONS].length; i++) {
        this._OFHelper.sendMessageToPluginWithInterceptors(
          event[OPERATIONS.FRAMEWORK_NOTIFICATIONS][i],
          message
        );
      }
    }
  }

  processSetPlugin(value) {
    this._selectedPlugin = value;
    if (this.plugins.length + 1 >= value) {
      this.plugins.forEach((plugin) => (plugin.inFocus = false));
      this.plugins[value].inFocus = true;

      // As any because for some reason it says it is a different type, even though it is the same
      OFHelper.selectedChannelApp$.next(this.plugins[value] as any);
    }
    this.contextualContactsService.selectApp(this.plugins[value].name);
    this.setHeight();
    this.registeredOperationsUpdated(this._OFHelper.registeredOperations);
  }

  get selectedPlugin() {
    return this._selectedPlugin;
  }

  constructor(
    private configurationService: ConfigurationService,
    private sanitizer: DomSanitizer,
    private appsService: AppsService,
    private changeDetectorRef: ChangeDetectorRef,
    private contextualContactsService: ContextualContactsService,
    private _OFHelper: OFHelper
  ) {
    this.config = configurationService.config;
    _OFHelper.registeredOperations$.subscribe((event) =>
      this.registeredOperationsUpdated(event)
    );
  }

  setHeight() {
    if (this.appsService && this.appsService.updateHeight) {
      this.appsService.updateHeight();
    }
  }

  getPluginIcon(plugin: DaVinciApp) {
    const result = this.sanitizer.sanitize(
      SecurityContext.URL,
      plugin.icon || this.config.defaultIcon
    );
    return result;
  }
}
