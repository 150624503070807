// eslint-disable-next-line no-shadow
enum HEIGHT_TYPE {
  Dynamic = 'Dynamic',
  Fixed = 'Fixed'
}
export interface IGlobalConfiguration {
  MaxAppLoadTime: number;
  InactivityTimer: number;
  dimensionChangeWaitTime: number;
  Height: number;
  HeightType: HEIGHT_TYPE;
  Width: number;
  WidthType: HEIGHT_TYPE;
  AppLogoUrl: string;
  LivePresenceGroupNameFilter: string[];
  LivePresenceChannelTypeFilter: string[];
  LivePresenceFilterOnPresence: string[];
  LivePresenceFilterOnOperation: string[];
  LivePresenceFilterOnApp: string[];
  LivePresenceHeightRatio: number;
  EnableDialpadAutocomplete: boolean;
  InteractionCleanupFrequency: number;
  InteractionExpirationTime: number;
}
import { StorageService } from 'src/app/storage.service';

const logoName = StorageService.getLogoPath();
export const defaultGlobalConfiguration: IGlobalConfiguration = {
  MaxAppLoadTime: 20 * 1000,
  InactivityTimer: -1,
  dimensionChangeWaitTime: 2,
  Height: 200,
  HeightType: HEIGHT_TYPE.Dynamic,
  Width: 200,
  WidthType: HEIGHT_TYPE.Dynamic,
  AppLogoUrl: '/assets/images/' + logoName,
  LivePresenceGroupNameFilter: [],
  LivePresenceChannelTypeFilter: [],
  LivePresenceFilterOnPresence: [],
  LivePresenceFilterOnOperation: [],
  LivePresenceFilterOnApp: [],
  LivePresenceHeightRatio: 50,
  EnableDialpadAutocomplete: true,
  InteractionCleanupFrequency: 60000,
  InteractionExpirationTime: 30000
};
