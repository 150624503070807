import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-logout-warn',
  templateUrl: './logout-warn.component.html',
  styleUrls: ['./logout-warn.component.scss']
})
export class LogoutWarnComponent implements OnInit, OnDestroy {
  timeRemaining;
  counterString = '1:00';
  warnCountdown: any;
  otherTabCancels: boolean;
  otherTabCancelInterval: any;
  otherSelectedLogoutInterval: any;

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<LogoutWarnComponent>) {}

  // Create counter for warning timer before auto logout
  ngOnInit() {
    localStorage.setItem('WarningState', 'false');
    localStorage.setItem('SelectedLogout', 'false');
    const expirationTime = new Date(localStorage.getItem('LoginTimeout'));
    this.warnCountdown = setInterval(() => {
      const currentTime = new Date();
      this.timeRemaining = expirationTime.getTime() - currentTime.getTime();
      this.timeRemaining = Math.ceil(this.timeRemaining / 1000);
      if (this.timeRemaining === 60) {
        this.counterString = '1:00';
      } else if (this.timeRemaining < 60 && this.timeRemaining >= 10) {
        this.counterString = '0:' + this.timeRemaining;
      } else if (this.timeRemaining < 10 && this.timeRemaining > 0) {
        this.counterString = '0:0' + this.timeRemaining;
      } else {
        this.counterString = '0:00';
        clearInterval(this.warnCountdown);
        this.dialogRef.close(true);
        this.apiService.stopTimersAndLogout();
        localStorage.setItem('SelectedLogout', 'true');
      }
      // this.counter--;
    }, 1000);
    // Check to see if another CS browser closed the warning
    this.otherTabCancelInterval = setInterval(() => {
      const warningStatus = localStorage.getItem('WarningState');
      if (warningStatus === 'true') {
        this.dialogRef.close(true);
        clearInterval(this.warnCountdown);
      }
    }, 250);
    // Check if other tab selected logout button
    this.otherSelectedLogoutInterval = setInterval(() => {
      const logoutStatus = localStorage.getItem('SelectedLogout');
      if (logoutStatus === 'true') {
        clearInterval(this.warnCountdown);
        this.dialogRef.close(true);
        this.apiService.stopTimersAndLogout();
      }
    }, 250);
  }

  ngOnDestroy() {
    clearInterval(this.otherTabCancelInterval);
    clearInterval(this.otherSelectedLogoutInterval);
    clearInterval(this.warnCountdown);
  }

  // Clear countdown and close the dialog. Inform other browsers that the user is active
  userReturned() {
    clearInterval(this.warnCountdown);
    clearInterval(this.otherSelectedLogoutInterval);
    clearInterval(this.otherTabCancelInterval);
    this.dialogRef.close(true);
    localStorage.setItem('WarningState', 'true');
  }

  // Skip countdown and logout
  logoutSelected() {
    clearInterval(this.warnCountdown);
    clearInterval(this.otherSelectedLogoutInterval);
    clearInterval(this.otherTabCancelInterval);
    this.dialogRef.close(true);
    localStorage.setItem('SelectedLogout', 'true');
    this.apiService.stopTimersAndLogout();
  }
}
