import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../services/configuration.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiErrorsInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigurationService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && req.url.includes(this.configService.config.apiUrl)) {
          this.router.navigate(['/error']);
        }

        return throwError(error);
      })
    );
  }
}
