import { Component, OnInit, Input } from '@angular/core';
import { DaVinciApp as IPlugin } from '../../../model/plugin';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-da-vinci-app',
  templateUrl: './da-vinci-app.component.html',
  styleUrls: ['./da-vinci-app.component.css']
})
export class DaVinciAppComponent {
  private _plugin: IPlugin;
  src: SafeResourceUrl;
  @Input() maxSize: boolean;
  @Input() set plugin(value) {
    this._plugin = value;
    if (value.src) {
      this.src = this.makeSourceSafe(value.src);
    }
  }

  get plugin() {
    return this._plugin;
  }

  constructor(private sanitizer: DomSanitizer) {}

  makeSourceSafe(src: string) {
    // NOTE: possible XSS by using bypass security
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }
}
