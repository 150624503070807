<div class="top" *ngIf="!profileSelected">
  <mat-card id="profile-select">
    <mat-card-title>
      <div class="logo">
        <img src='{{ "assets/images/" + logoName }}' alt="">
      </div>
    </mat-card-title>
    <div class="prompt">
      <div  class="usernameDiv">
        <label class="usernameLabel" [title]="getUserName()">{{getUserName()}}</label>
      </div>
      <div class="backToLoginScreen"  (keyup.enter)="logout('clicked on logout on profile selection page')"  (click) = "logout('clicked on logout on profile selection page')">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <label tabindex="0" role="link"> Logout </label>
      </div>
      <strong id="profile-prompt">Select a Profile</strong>
    </div>
    <div class="profile-buttons">
      <div *ngFor="let profile of profiles" (click)="setProfile(profile.profileid)" class="profile-button">
        <button mat-button class="mat-raised-button">
          <label>{{profile.profileName}}</label>
        </button>
      </div>
    </div>
  </mat-card>
</div>

<div *ngIf="profileSelected">
  <div class="gray-out" *ngIf="appsLoaded === false || loggingOut || layerToGrayOutBelow > 0"
    [style.z-index]="(layerToGrayOutBelow * 2) + 5 - 1" [style.background]="layerToGrayOutBelow > 0 ? '#c0c0c0' : null">
    <div class="spinner-container" *ngIf="appsLoaded === false || loggingOut">
      <img class="spinner" src="./../../assets/images/view_progress.gif" />
      <div *ngIf="showReload && !loggingOut" class="reload">
        <p>Apps are taking a long time to load</p>
        <button mat-button (click)="reloadClicked()">
          <mat-icon>refresh</mat-icon>
          Try Again
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let layer of plugins | keyvalue; index as i">
    <div [class]="getCRMPanelPlugins(layer.key).length > 0 ? 'main-100vh app-layer' : 'main app-layer'"
      [style.z-index]="(layer.key * 2) + 5" fxLayout="row" fxLayoutAlign="start start">
      <app-panel [isBottomLayer]="layer.key === '0'" [style.flex]="
          getCRMPanelPlugins(layer.key).length > 0 ? '0 0 ' + panelSize + 'px' : '1 1 0'
        " [width]="getCRMPanelPlugins(layer.key).length > 0 ? panelSize + 'px' : '100%'" [appLogoUrl]="appLogoUrl"
        class="mainPanel" [plugins]="plugins[layer.key]" [notificationMessages]="notificationMessages"></app-panel>

      <ng-container *ngIf="getCRMPanelPlugins(layer.key).length > 0">
        <app-splitter class="splitter" (position)="panelSize = $event"></app-splitter>

        <ng-container *ngFor="let plugin of getCRMPanelPlugins(layer.key)">
          <app-da-vinci-app class="crmPanel" [maxSize]="true" [plugin]="plugin"></app-da-vinci-app>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

</div>
