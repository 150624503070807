import { Component, OnInit, ContentChild, ViewChild, Output, EventEmitter } from '@angular/core';
import { MouseService } from '../../services/mouse.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-splitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.css']
})
export class SplitterComponent implements OnInit {
  @Output() position = new EventEmitter<number>();
  @ViewChild('ghost', { static: true }) ghost;
  @ViewChild('real', { static: true }) real;
  private mouseSubscriptions: Subscription[] = [];
  isMouseDown = false;

  constructor(private mouseService: MouseService) {}

  ngOnInit() {}

  dragStart(event: DragEvent) {
    event.dataTransfer.dropEffect = 'move';
  }

  onMouseDown(mouseDownEvent: MouseEvent) {
    if (mouseDownEvent.button === 0) {
      this.clearSubscriptions();
      this.isMouseDown = true;

      const widthOffset = this.real.nativeElement.clientWidth / 2;
      this.mouseSubscriptions.push(
        ...[
          this.mouseService.mouseUp.subscribe((event) => {
            this.isMouseDown = false;
            this.clearSubscriptions();
            this.position.emit(event.x);
          }),
          this.mouseService.mouseMove.subscribe((event) => {
            this.ghost.nativeElement.style.left = event.x - widthOffset + 'px';
          })
        ]
      );
      this.ghost.nativeElement.style.left = mouseDownEvent.x - widthOffset + 'px';
      this.ghost.nativeElement.style.height = this.real.nativeElement.clientHeight + 'px';
      this.ghost.nativeElement.style.width = this.real.nativeElement.clientWidth + 'px';
      this.ghost.nativeElement.style.top = this.real.nativeElement.offsetTop + 'px';
    }
  }

  onDrag(event: DragEvent) {
    event.preventDefault();
  }

  clearSubscriptions() {
    for (const subscription of this.mouseSubscriptions) {
      subscription.unsubscribe();
    }
    this.mouseSubscriptions = [];
  }
}
