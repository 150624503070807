import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  constructor(private api: ApiService, private _router: Router, private snackBar: MatSnackBar, private location: Location) {}

  public message = 'User does not have license.Please contact your DaVinci administrator for license.';

  ngOnInit() {
    if (this.location.path().includes('error/profile')) {
      this.message = 'The selected profile was not found. Please contact your DaVinci administrator for proper profile assignment.';
    }
  }

  async logout() {
    await this.api.logout().catch(() => {});
    this._router.navigate(['home']);
  }
}
