import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-plugin-tab-label',
  templateUrl: './plugin-tab-label.component.html',
  styleUrls: ['./plugin-tab-label.component.scss']
})
export class PluginTabLabelComponent implements OnInit {
  @Input() src: any;
  @Input() name: string;

  private _alerts: number = null;
  private _alertSubscription: Subscription = null;
  @Input() set alerts(value: number | Observable<number> | null) {
    if (value !== null) {
      if (this._alertSubscription !== null) {
        this._alertSubscription.unsubscribe();
        this._alertSubscription = null;
      }

      if (typeof value === 'number') {
        this._alerts = value;
      } else {
        this._alertSubscription = value.subscribe(
          (alerts) => {
            if (alerts <= 0) {
              this._alerts = null;
            } else {
              this._alerts = alerts;
            }
            this.changeDetectorRef.detectChanges();
          },
          (e) => this.loggerService.logger.logError(`PluginTabLabelComponent - alerts error: ${JSON.stringify(e)}`)
        );
      }
    }
  }

  get alerts() {
    return this._alerts;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private loggerService: LoggerService) {}

  ngOnInit(): void {}
}
