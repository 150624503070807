import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  setSoftphoneUrl() {
    localStorage.setItem('softphoneUrl', document.location.href);
  }

  getSoftphoneUrl() {
    return localStorage.getItem('softphoneUrl');
  }

  static getLogoPath() {
    if (document.location.href.includes('avaya')) {
      return 'avayaLogo.png';
    } else {
      return 'da_vinci.png';
    }
  }
}
