import { Component, OnInit, Input } from '@angular/core';
import { IPanelItem } from '../../../model/IPanelItem';

@Component({
  selector: 'app-constant-size',
  templateUrl: './constant-size.component.html',
  styleUrls: ['./constant-size.component.css']
})
export class ConstantSizeComponent {
  @Input() params: {
    height?: string;
    width?: string;
    children?: IPanelItem[];
  };

  constructor() {}
}
