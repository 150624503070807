import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { BaseAuthInfo } from '../model/auth/BaseAuthInfo';
import { StorageService } from '../storage.service';
import { LoggerService } from 'src/app/services/logger.service';
import { NOTIFICATION_TYPE, sendNotification } from '../api/AmcApi';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-saml-auto-login',
  templateUrl: './saml-auto-login.component.html',
  styleUrls: ['./saml-auto-login.component.scss']
})
export class SamlAutoLoginComponent implements OnInit, OnDestroy {
  name: string;
  accountAuthTypeId: string;
  authInfo: BaseAuthInfo;
  loading: boolean;
  showLogin: boolean;
  logoName: string;
  accountId: string;
  currentAuthInfoName: string;
  samlWindow: WindowProxy;

  constructor(
    private route: ActivatedRoute,
    private _api: ApiService,
    private router: Router,
    private storageService: StorageService,
    private loggerService: LoggerService,
    private snackBar: MatSnackBar
  ) {
    this.logoName = StorageService.getLogoPath();
  }

  async ngOnInit() {
    this.currentAuthInfoName = '';
    this.loading = true;
    this.route.params.subscribe(async (params) => {
      this.showLogin = false;
      this.accountId = params['accountid'];
      const name = params['name'];
      const authInfos = await this._api.GetAuthInfoByAccountId(this.accountId);
      const auth = authInfos.filter((auth2) => auth2.name === name)[0];
      this.currentAuthInfoName = name;

      const accountauthtypeid = auth.accountAuthTypeId;
      const samlLoginStamp = localStorage.getItem('SAMLLoginStamp');
      let lastLoginTimeDateObj: Date;
      let difference = 0;
      this.storageService.setSoftphoneUrl();
      this.authInfo = await this._api.getAuthInfoByAccountAuthId(accountauthtypeid);
      if (samlLoginStamp) {
        lastLoginTimeDateObj = new Date(samlLoginStamp);
        difference = (new Date().valueOf() - lastLoginTimeDateObj.valueOf()) / (1000 * 60);
      }
      const isActive = await this._api.isActive();
      if (isActive) {
        this.router.navigate(['']);
      } else if (window.sessionStorage.getItem('samlJwtToken') && window.sessionStorage.getItem('appJwtToken')) {
        this.getToken();
      } else if (difference > this.authInfo.autoLoginWaitTime || !samlLoginStamp) {
        await this._api.retrieveSAMLTokens({ accountId: this.accountId });
        const url = this.getSSOUrl(this.accountId, name);
        this.samlWindow = window.open(url, '_blank');

        this.getToken();
      } else {
        this.showLogin = true;
      }

      this.loading = false;
    });
  }

  ngOnDestroy() {
    this._api.stopSAMLPolling();
  }

  getSSOUrl(accountid, name) {
    const hostname = window.location.origin;
    let url = `${hostname}/account/${accountid}/${name}`;

    if (sessionStorage.getItem('samlJwtToken')) {
      url += `?samlToken=${sessionStorage.getItem('samlJwtToken')}`;
    }

    return url;
  }

  getSSOUrlFromAuthInfo(authInfo: BaseAuthInfo) {
    const hostname = window.location.origin;
    let url = `${hostname}/account/${authInfo.accountId}/${authInfo.name}`;

    if (sessionStorage.getItem('samlJwtToken')) {
      url += `?samlToken=${sessionStorage.getItem('samlJwtToken')}`;
    }

    return url;
  }

  cancelSAMLLogin() {
    this.showLogin = true;
    this._api.stopSAMLPolling();
    window.sessionStorage.removeItem('appJwtToken');
    window.sessionStorage.removeItem('samlJwtToken');

    if (this.samlWindow) {
      this.samlWindow.close();
      this.samlWindow = null;
    }
  }

  async SAMLLogin(authInfo: BaseAuthInfo) {
    try {
      await this._api.retrieveSAMLTokens({ accountId: this.accountId });
      this.loading = true;
      this.showLogin = false;
      this.samlWindow = window.open(this.getSSOUrlFromAuthInfo(this.authInfo), '_blank');

      this.getToken();
    } catch (e) {
      this.loggerService.logger.logError(`SAMLLogin failed for account ID ${this.accountId} with exception : ${JSON.stringify(e)}`);
      sendNotification('SAML Login Failed, please try again', NOTIFICATION_TYPE.Error);
    }
  }

  private getToken() {
    try {
      this.loggerService.logger.logDebug(`Polling for access token with SAML and app token for account ID : ${this.accountId}`);

      if (sessionStorage.getItem('samlJwtToken') && sessionStorage.getItem('appJwtToken')) {
        this._api.stopSAMLPolling();
        const startTime = new Date().getTime();
        this._api
          .startSAMLPolling({
            samlJwtToken: sessionStorage.getItem('samlJwtToken'),
            appJwtToken: sessionStorage.getItem('appJwtToken')
          })
          .subscribe(
            (result) => {
              if (result) {
                this.loggerService.logger.logDebug(`Polling for SAML access token successful for account ID : ${this.accountId}`);

                window.localStorage.setItem('SAMLLoginStamp', new Date().toString());
                window.sessionStorage.removeItem('appJwtToken');
                window.sessionStorage.removeItem('samlJwtToken');

                window.location.reload();
              }
            },
            (error) => {
              this.loggerService.logger.logError(
                `Polling for SAML access token failed for account ID : ${this.accountId} with exception : ${JSON.stringify(error)}`
              );

              window.sessionStorage.removeItem('appJwtToken');
              window.sessionStorage.removeItem('samlJwtToken');

              if (this.samlWindow) {
                this.samlWindow.close();
                this.samlWindow = null;
              }

              window.location.reload();
            },
            () => {
              this.loggerService.logger.logDebug(`polling for SAML access token completed for account ID : ${this.accountId}`);

              if (new Date().getTime() - startTime >= this._api.samlPollTimeoutSeconds * 1000) {
                this.loggerService.logger.logError('Polling for SAML access token timed out');
                this.snackBar.open('Login attempt timed out, please try again', 'DISMISS');
              }

              this.cancelSAMLLogin();
            }
          );
      } else {
        this.cancelSAMLLogin();
        this.snackBar.open('SSO login failed, please try again', 'DISMISS');
      }
    } catch (e) {
      this.loggerService.logger.logError(`SAML login failed for account ID : ${this.accountId} with exception : ${JSON.stringify(e)}`);
      this.cancelSAMLLogin();
      this.snackBar.open('SSO login failed, please try again', 'DISMISS');
    }
  }
}
