/**
 * @packageDocumentation
 * @module DaVinci_API
 */

/**
 * A field of a record/entity in CRM.
 */
export interface IField {
  DevName: string;
  DisplayName: string;
  Value: any;
}
