<div class="main" [style.width]="width">
  <div [class]="isBottomLayer ? 'top gray-bg' : 'top'">
    <div class="left">
      <ng-container *ngFor="
          let item of getTopLeftItems();
          let index = index;
          last as isLast
        ">
        <app-panel-item [item]="item"></app-panel-item>
      </ng-container>
    </div>
    <div class="right">
      <ng-container *ngFor="
          let item of getTopRightItems();
          let index = index;
          last as isLast
        ">
        <app-panel-item [item]="item"></app-panel-item>
      </ng-container>
    </div>
  </div>

  <div class = 'topMiddleWithoutPadding'>
    <div class="divNotifications" *ngIf="notificationMessages.length !== 0">
      <app-notification-panel [notificationMessages]="notificationMessages"></app-notification-panel>
    </div>

    <div class="topBanner" style.display="block" style="padding-left: 0px; padding-right: 0px">
      <ng-container style.display="block" *ngFor="let item of getTopBannerItems(); let index = index; last as isLast">
        <app-panel-item [item]="item"></app-panel-item>
      </ng-container>
    </div>

    <app-keypad *ngIf="isBottomLayer"></app-keypad>
    <!-- Fill the same space as keypad w/ below -->
    <div *ngIf="!isBottomLayer" style="height: 24px;"></div>

    <div class="dialPad" style.display="block" style="width: 100%; padding-left: 0px; padding-right: 0px">
      <ng-container style.display="block" *ngFor="
          let item of getDialPadItems();
          let index = index;
          last as isLast
        ">
        <app-panel-item style.display="block" [item]="item"></app-panel-item>
      </ng-container>
    </div>
  </div>

  <div class="middle">
    <ng-container *ngFor="let item of getMiddleItems(); let index = index; last as isLast">
      <app-panel-item [item]="item"></app-panel-item>
    </ng-container>
  </div>
</div>

<!-- TODO: make the footer dynamics -->
<div [class]="isBottomLayer ?  'footer gray-bg' : 'footer'" [style.width]="width">
  <mat-divider></mat-divider>
  <div class="footer-content" fxLayout="row" fxLayoutAlign="space-between end">
    <div fxFlex="0 0 55px">
      <input type="image" class="logo" [src]="appLogoUrl" />
    </div>

    <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="start end">
      <ng-container *ngFor="
          let item of getBottomLeftItems();
          let index = index;
          last as isLast
        ">
        <app-panel-item [item]="item"></app-panel-item>
      </ng-container>
    </div>

    <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="end end">
      <ng-container *ngFor="
          let item of getBottomRightItems();
          let index = index;
          last as isLast
        ">
        <app-panel-item [item]="item"></app-panel-item>
      </ng-container>
    </div>
  </div>
</div>
