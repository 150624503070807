// eslint-disable-next-line no-shadow
export enum PLUGIN_POSITION {
  top_left = 'TOP_LEFT',
  top_right = 'TOP_RIGHT',
  bottom_left = 'BOTTOM_LEFT',
  bottom_right = 'BOTTOM_RIGHT',
  center = 'CENTER',
  center_no_tab = 'CENTER_NO_TAB',
  default = 'DEFAULT',
  hidden = 'HIDDEN',
  crm_panel = 'CRM_PANEL',
  dial_pad = 'DIAL_PAD',
  top_banner = 'TOP_BANNER'
}
