import { Injectable } from '@angular/core';
import { OFHelper } from './OFHelper';

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  constructor(private _OFHelper: OFHelper) {}

  public async updateHeight() {
    if (this._OFHelper != null) {
      window.setTimeout(() => this._OFHelper.sendSetSoftphoneHeight(), 500);
    }
  }
}
