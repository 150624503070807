import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { Logger, LOG_SOURCE, LOG_LEVEL } from '../api/Logger';
import { ILoggerConfiguration, defaultLoggerConfiguration } from '../api/models/LoggerConfiguration';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  public logger: Logger;

  constructor(private configService: ConfigurationService) {
    this.logger = new Logger(LOG_SOURCE.OpenFramework, false, this.configService?.config?.apiUrl);
    this.logger.setConfiguration(defaultLoggerConfiguration);
  }

  initialize(loggerConfig: ILoggerConfiguration) {
    this.logger = new Logger(LOG_SOURCE.OpenFramework, false, this.configService?.config?.apiUrl);
    this.logger.setConfiguration(loggerConfig);
  }

  setLogLevel(logLevel: LOG_LEVEL): void {
    this.logger.logLevel = logLevel;
  }
}
