import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAuthInfo } from 'src/app/model/auth/BaseAuthInfo';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { AUTH_TYPE } from 'src/app/model/auth/AuthType';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StorageService } from 'src/app/storage.service';
import { AMCValidators } from 'src/app/model/Validators/AMCValidators';
import { NOTIFICATION_TYPE, sendNotification } from 'src/app/api/AmcApi';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-auth-profile',
  templateUrl: './login-auth-profile.component.html',
  styleUrls: ['./login-auth-profile.component.scss']
})
export class LoginAuthProfileComponent implements OnInit, OnDestroy {
  error: boolean;
  errorMessage: string;
  inProgress: boolean;
  loginAuthInfos$: Observable<BaseAuthInfo[]>;
  Password = new FormControl('', [Validators.required, AMCValidators.cannotContainSpace]);
  isSAMLPolling = false;
  currentAuthInfoName: string;
  authInfos: BaseAuthInfo[];
  daVinciAuthInfo: BaseAuthInfo;
  daivnciAuthSelected_flag: boolean;
  samlWindow: WindowProxy;

  @Input() userName: string;
  @Output() backAction = new EventEmitter<void>();
  logoName: string;
  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    public configService: ConfigurationService,
    private storageService: StorageService,
    private snackBar: MatSnackBar
  ) {
    this.currentAuthInfoName = '';
    this.error = false;
    this.inProgress = false;
    this.daivnciAuthSelected_flag = false;
  }

  ngOnInit() {
    this.loginAuthInfos$ = this.apiService.getLoginAuthInfosAsObservable();
    this.logoName = StorageService.getLogoPath();
    this.loginAuthInfos$.subscribe((baseAuthInfos) => {
      this.authInfos = baseAuthInfos.filter((auth2) => auth2.authTypeId !== AUTH_TYPE.DAVINCI);
      this.daVinciAuthInfo = baseAuthInfos.find((auth2) => auth2.authTypeId === AUTH_TYPE.DAVINCI);
      if (baseAuthInfos.length === 1 && this.authInfos.length === 1) {
        this.SAMLLogin(this.authInfos[0]);
      } else if (baseAuthInfos.length === 1) {
        // authype is DaVinci
        this.daivnciAuthSelected_flag = true;
      }
    });
  }

  ngOnDestroy() {
    this.isSAMLPolling = false;
    this.apiService.stopSAMLPolling();
  }

  forgotPassword() {}

  async onSubmit() {
    this.inProgress = true;
    this.error = false;
    this.errorMessage = 'Invalid UserName or Password';
    try {
      if (this.Password.valid) {
        await this.apiService.login(this.userName, this.Password.value);
        this.error = false;
        this.loggerService.logger.logDebug(`LoginComponent successful login user=${this.userName}`);
        window.location.reload();
      }
    } catch (e) {
      this.loggerService.logger.logWarning(`LoginComponent failed login user=${this.userName} exception=${JSON.stringify(e)}`);
      this.error = true;
      if (e.error && e.error.reasonPhrase) {
        this.errorMessage = e.error.reasonPhrase;
      }
    } finally {
      this.inProgress = false;
    }
  }

  getSSOUrl(authInfo: BaseAuthInfo) {
    const hostname = window.location.origin;
    let url = `${hostname}/account/${authInfo.accountId}/${authInfo.name}`;

    if (sessionStorage.getItem('samlJwtToken')) {
      url += `?samlToken=${sessionStorage.getItem('samlJwtToken')}`;
    }

    return url;
  }

  backToLoginScreen() {
    if (this.samlWindow) {
      this.samlWindow.close();
      this.samlWindow = null;
    }

    this.backAction.emit();
  }

  backToLoginScreenKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.backToLoginScreen();
    }
  }

  cancelSAMLLogin() {
    this.isSAMLPolling = false;
    this.apiService.stopSAMLPolling();
    window.sessionStorage.removeItem('appJwtToken');
    window.sessionStorage.removeItem('samlJwtToken');

    if (this.samlWindow) {
      this.samlWindow.close();
      this.samlWindow = null;
    }
  }

  async SAMLLogin(authInfo: BaseAuthInfo) {
    try {
      this.loggerService.logger.logDebug(`Polling for access token with SAML and app token for user ${this.userName}`);
      this.storageService.setSoftphoneUrl();
      await this.apiService.retrieveSAMLTokens({ username: this.userName });
      this.samlWindow = window.open(this.getSSOUrl(authInfo), '_blank');

      if (sessionStorage.getItem('samlJwtToken') && sessionStorage.getItem('appJwtToken')) {
        this.currentAuthInfoName = authInfo.name;
        this.isSAMLPolling = true;
        this.apiService.stopSAMLPolling();
        const startTime = new Date().getTime();
        this.apiService
          .startSAMLPolling({
            samlJwtToken: sessionStorage.getItem('samlJwtToken'),
            appJwtToken: sessionStorage.getItem('appJwtToken')
          })
          .subscribe(
            (result) => {
              if (result) {
                this.loggerService.logger.logDebug(`Polling for SAML access token successful for user ${this.userName}`);

                window.localStorage.setItem('username', this.userName);
                window.localStorage.setItem('SAMLLoginStamp', new Date().toString());
                this.samlWindow = null;
                this.cancelSAMLLogin();

                window.location.reload();
              }
            },
            (error) => {
              this.loggerService.logger.logError(`Polling for SAML access token failed for user=${this.userName} with exception=${JSON.stringify(error)}`);

              this.cancelSAMLLogin();

              window.location.reload();
            },
            () => {
              this.loggerService.logger.logDebug(`Polling for SAML access token completed for user=${this.userName}`);

              if (new Date().getTime() - startTime >= this.apiService.samlPollTimeoutSeconds * 1000) {
                this.loggerService.logger.logError('Polling for SAML access token timed out');
                this.snackBar.open('Login attempt timed out, please try again', 'DISMISS');
              }

              this.cancelSAMLLogin();
            }
          );
      } else {
        this.currentAuthInfoName = '';
        this.cancelSAMLLogin();
        this.snackBar.open('SSO login failed, please try again', 'DISMISS');
      }
    } catch (e) {
      this.loggerService.logger.logError(`SAML Login failed for user=${this.userName} with exception=${JSON.stringify(e)}`);
      this.currentAuthInfoName = '';
      this.cancelSAMLLogin();

      this.snackBar.open('SSO Login Failed, please try again', 'DISMISS');
    }
  }
}
