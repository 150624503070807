<div id="PluginContainer">
  <mat-tab-group (selectedIndexChange)="selectedPlugin=$event" color="accent" [ngClass]="{'center-plugins-tab-group': true, 'recursive-height': true, 'recursive-width': true}"
    *ngIf="plugins.length > 1">
    <mat-tab *ngFor="let plugin of plugins; let index = index">
      <ng-template mat-tab-label>
        <app-plugin-tab-label [name]="plugin.name" [src]="getPluginIcon(plugin)" [alerts]="plugin.alertsObservable"></app-plugin-tab-label>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngFor="let plugin of plugins; let index = index; last as isLast">
    <div [class.center-plugin]="true" [class.hide-iframe]="index !== selectedPlugin  && !(isLast && index === 0)">
      <app-da-vinci-app [plugin]="plugin"></app-da-vinci-app>
    </div>
  </ng-container>
</div>
